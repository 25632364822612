import "normalize.css";
import type { AppProps } from "next/app";
import PlausibleProvider from "next-plausible";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Analytics } from "@vercel/analytics/react";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <PlausibleProvider domain="devsforgood.com">
        <Component {...pageProps} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          draggable={false}
          closeOnClick
          pauseOnHover={false}
        />
        <Analytics />
      </PlausibleProvider>
    </>
  );
}
